import * as React from "react"

import Layout from "../components/layout/layout"
import HomeHeroSection from "../components/sections/home-hero-section"
import ShowcaseSection from "../components/sections/showcase-section"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Gatsby Sites Showcase" />
    <HomeHeroSection />

    <div className='w-full max-w-screen-xl flex items-center justify-center mx-auto mt-16 mb-8'>
      <div>
        <h2 className="text-2xl text-center font-medium">Featured Gatsby Websites</h2>
      </div>
    </div>

    <ShowcaseSection />
  </Layout>
)

export default IndexPage

import React from "react"

const HomeHeroSection = () => (
  <div className="flex justify-center items-center px-4 pt-12 pb-12">
    <div className='w-full max-w-screen-xl flex flex-col xl:flex-row items-center mx-auto'>
      <div className='w-full md:w-10/12 xl:w-2/3 flex flex-col items-center mx-auto'>
        <h1 className='text-4xl md:text-7xl font-extrabold text-center'>
          Gatsby Sites
        </h1>

        <h2 className='w-full lg:w-9/12 text-center mt-6'>Gallery of websites made with Gatsby React framework</h2>
      </div>
    </div>
  </div>
)

export default HomeHeroSection
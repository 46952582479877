import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const ShowcaseSection = () => (
  <div className="flex justify-center items-center px-4 pt-12 pb-48">
    <div className='w-full max-w-screen-xl grid grid-cols-1 xl:grid-cols-2 gap-20 mx-auto'>
      <div>
        <StaticImage
          src="../../images/screenshots/bitcoin-website.png"
          width={1400}
          quality={95}
          placeholder="tracedSVG"
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Bitcoin website"
          className="shadow-lg"
        />
        <div className="flex items-start justify-between mt-4">
          <h2 className="text-lg font-bold">Bitcoin</h2>
          <a
            href="https://www.bitcoin.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm underline"
          >
            Visit site
          </a>
        </div>
      </div>

      <div>
        <StaticImage
          src="../../images/screenshots/figma-website.png"
          width={1400}
          quality={95}
          placeholder="tracedSVG"
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Figma website"
          className="shadow-lg"
        />
        <div className="flex items-start justify-between mt-4">
          <h2 className="text-lg font-bold">Figma</h2>
          <a
            href="https://www.figma.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm underline"
          >
            Visit site
          </a>
        </div>
      </div>

      <div>
        <StaticImage
          src="../../images/screenshots/spotify-design-website.png"
          width={1400}
          quality={95}
          placeholder="tracedSVG"
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Spotify Design website"
          className="shadow-lg"
        />
        <div className="flex items-start justify-between mt-4">
          <h2 className="text-lg font-bold">Spotify Design</h2>
          <a
            href="https://spotify.design/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm underline"
          >
            Visit site
          </a>
        </div>
      </div>

      <div>
        <StaticImage
          src="../../images/screenshots/airbnb-cereal-website.png"
          width={1400}
          quality={95}
          placeholder="tracedSVG"
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Airbnb Cereal website"
          className="shadow-lg"
        />
        <div className="flex items-start justify-between mt-4">
          <h2 className="text-lg font-bold">Airbnb Cereal</h2>
          <a
            href="https://airbnb.design/cereal/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm underline"
          >
            Visit site
          </a>
        </div>
      </div>

      <div>
        <StaticImage
          src="../../images/screenshots/airbnb-engineering-website.png"
          width={1400}
          quality={95}
          placeholder="tracedSVG"
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Airbnb Engineering and Data Science website"
          className="shadow-lg"
        />
        <div className="flex items-start justify-between mt-4">
          <h2 className="text-lg font-bold">Airbnb Engineering & Data Science</h2>
          <a
            href="https://airbnb.io/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm underline"
          >
            Visit site
          </a>
        </div>
      </div>

      <div>
        <StaticImage
          src="../../images/screenshots/affirm-website.png"
          width={1400}
          quality={95}
          placeholder="tracedSVG"
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Affirm website"
          className="shadow-lg"
        />
        <div className="flex items-start justify-between mt-4">
          <h2 className="text-lg font-bold">Affirm</h2>
          <a
            href="https://www.affirm.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm underline"
          >
            Visit site
          </a>
        </div>
      </div>

      <div>
        <StaticImage
          src="../../images/screenshots/hopper-website.png"
          width={1400}
          quality={95}
          placeholder="tracedSVG"
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Hopper website"
          className="shadow-lg"
        />
        <div className="flex items-start justify-between mt-4">
          <h2 className="text-lg font-bold">Hopper</h2>
          <a
            href="https://www.hopper.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm underline"
          >
            Visit site
          </a>
        </div>
      </div>

      <div>
        <StaticImage
          src="../../images/screenshots/flamingo-website.png"
          width={1400}
          quality={95}
          placeholder="tracedSVG"
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Flamingo website"
          className="shadow-lg"
        />
        <div className="flex items-start justify-between mt-4">
          <h2 className="text-lg font-bold">Flamingo</h2>
          <a
            href="https://www.shopflamingo.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm underline"
          >
            Visit site
          </a>
        </div>
      </div>

      <div>
        <StaticImage
          src="../../images/screenshots/ibm-design-website.png"
          width={1400}
          quality={95}
          placeholder="tracedSVG"
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="IBM Design website"
          className="shadow-lg"
        />
        <div className="flex items-start justify-between mt-4">
          <h2 className="text-lg font-bold">IBM Design</h2>
          <a
            href="https://www.ibm.com/design/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm underline"
          >
            Visit site
          </a>
        </div>
      </div>

      <div>
        <StaticImage
          src="../../images/screenshots/draftkings-website.png"
          width={1400}
          quality={95}
          placeholder="tracedSVG"
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="DraftKings website"
          className="shadow-lg"
        />
        <div className="flex items-start justify-between mt-4">
          <h2 className="text-lg font-bold">DraftKings</h2>
          <a
            href="https://www.draftkings.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm underline"
          >
            Visit site
          </a>
        </div>
      </div>

      <div>
        <StaticImage
          src="../../images/screenshots/linktree-website.png"
          width={1400}
          quality={95}
          placeholder="tracedSVG"
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Linktree website"
          className="shadow-lg"
        />
        <div className="flex items-start justify-between mt-4">
          <h2 className="text-lg font-bold">Linktree</h2>
          <a
            href="https://linktr.ee/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm underline"
          >
            Visit site
          </a>
        </div>
      </div>

      <div>
        <StaticImage
          src="../../images/screenshots/wealthsimple-website.png"
          width={1400}
          quality={95}
          placeholder="tracedSVG"
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Wealthsimple website"
          className="shadow-lg"
        />
        <div className="flex items-start justify-between mt-4">
          <h2 className="text-lg font-bold">Wealthsimple</h2>
          <a
            href="https://www.wealthsimple.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm underline"
          >
            Visit site
          </a>
        </div>
      </div>

      <div>
        <StaticImage
          src="../../images/screenshots/sainsburys-website.png"
          width={1400}
          quality={95}
          placeholder="tracedSVG"
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Sainsbury's website"
          className="shadow-lg"
        />
        <div className="flex items-start justify-between mt-4">
          <h2 className="text-lg font-bold">Sainsbury's</h2>
          <a
            href="https://www.sainsburys.co.uk/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm underline"
          >
            Visit site
          </a>
        </div>
      </div>

      <div>
        <StaticImage
          src="../../images/screenshots/contentful-website.png"
          width={1400}
          quality={95}
          placeholder="tracedSVG"
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Contentful website"
          className="shadow-lg"
        />
        <div className="flex items-start justify-between mt-4">
          <h2 className="text-lg font-bold">Contentful</h2>
          <a
            href="https://www.contentful.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm underline"
          >
            Visit site
          </a>
        </div>
      </div>

      <div>
        <StaticImage
          src="../../images/screenshots/sendgrid-website.png"
          width={1400}
          quality={95}
          placeholder="tracedSVG"
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="SendGrid website"
          className="shadow-lg"
        />
        <div className="flex items-start justify-between mt-4">
          <h2 className="text-lg font-bold">SendGrid</h2>
          <a
            href="https://sendgrid.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm underline"
          >
            Visit site
          </a>
        </div>
      </div>

      <div>
        <StaticImage
          src="../../images/screenshots/autoloadit-website.png"
          width={1400}
          quality={95}
          placeholder="tracedSVG"
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="AutoloadIT website"
          className="shadow-lg"
        />
        <div className="flex items-start justify-between mt-4">
          <h2 className="text-lg font-bold">AutoloadIT</h2>
          <a
            href="https://autoloadit.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm underline"
          >
            Visit site
          </a>
        </div>
      </div>
    </div>
  </div>
)

export default ShowcaseSection